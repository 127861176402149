import React, {useEffect, useState} from "react";
import {Box, Icon, Message, Search, Tree} from "@alifd/next";
import * as resourceModel from "@/models/admin/resource.model"
import {loopParseTreeNode} from "@/utils/TreeUtil";
import {TreeObject} from "@/types/core/TreeObject";
import {Resource} from "@/types/admin/Resource";
import {openErrorNotification} from "@/utils/notificationUtil";
import {HttpError} from "@/httpclient";

export interface AssignPermissionTreeProps {
    clientId: string; // ID
    roleId: string; // ROLE ID
}

const AssignPermissionTree: React.FC<AssignPermissionTreeProps> = (props) => {
    const {clientId, roleId} = props;
    // 查询资源树
    const [resourceTreeDataSource, setResourceTreeDataSource] = useState<TreeObject<Resource>[]>([]);
    const {data: assignedResourcesResponse, isLoading: isLoadingResourceTree} = resourceModel.useFindAssignedResourcesByRoleWithId(clientId, roleId);

    // 树展开状态
    const [treeExpandState, setExpandTreeState] = useState<{ expandedKeys: string[], autoExpandParent: boolean }>({
        expandedKeys: [],
        autoExpandParent: true
    });
    // 搜索匹配KEYS
    const [matchedKeys, setMatchedKeys] = useState<string[]>([]);
    // 选中节点KEYS
    const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

    // 刷新树
    const refreshResourceTree = resourceModel.useRefreshResourceTree(clientId);

    // 提交分配资源函数
    const {mutateAsync: assignResourcesToRole} = resourceModel.useAssignResourcesToRole(roleId)

    useEffect(() => {
        if (assignedResourcesResponse && assignedResourcesResponse.resourceTree && assignedResourcesResponse.resourceTree.length) {
            const tree = loopParseTreeNode(assignedResourcesResponse.resourceTree, (x) => {
                const newNode = {
                    key: x.key,
                    label: x.label,
                    order: x.order,
                    children: x.children || [],
                    type: x.extra?.resourceType || "LEFT_MENU",
                    isLeaf: x.extra?.resourceType != "TOP_MENU" && x.extra?.resourceType != "LEFT_MENU",
                    icon: x.extra?.icon ? <Icon type={x.extra?.icon}/> : undefined
                } as TreeObject<Resource>;
                return newNode;
            });
            setResourceTreeDataSource(tree);
            setCheckedKeys(assignedResourcesResponse.resourceIds);
        }
    }, [assignedResourcesResponse]);

    // 搜索树节点
    const handleSearchTreeNode = (value: any) => {
        value = value.trim();
        if (!value) {
            setMatchedKeys([])
            return;
        }

        const matchedKeys: string[] = [];
        const loop = (data: TreeObject<Resource>[] | undefined) => {
            if (data && data.length) {
                data.forEach(item => {
                    if (item.label.indexOf(value) > -1) {
                        matchedKeys.push(item.key);
                    }
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                });
            }
        }
        loop(resourceTreeDataSource);
        setExpandTreeState({
            expandedKeys: [...matchedKeys],
            autoExpandParent: true
        });
        setMatchedKeys(matchedKeys);
    }

    // 过滤树节点
    const filterTreeNode = (node: any): boolean => {
        return (
            matchedKeys && (matchedKeys.indexOf(node.props.eventKey) > -1)
        );
    };

    // 展开树
    const handleExpandTree = (expandedKeys: string[], extra: Object) => {
        setExpandTreeState({
            expandedKeys: expandedKeys,
            autoExpandParent: false
        });
    };

    // 选择树节点
    function handleCheckTreeNode(checkedKeys: string[], extra: Object) {
        // 提交分配资源
        assignResourcesToRole(checkedKeys).then(() => {
            Message.success("权限分配成功");
            setCheckedKeys(checkedKeys);
        }).catch((reason: HttpError) => {
            openErrorNotification(reason.status, reason.message)
        })
    }

    return <div>

        {
            resourceTreeDataSource.length > 0 ?
                <div>
                    <Box direction={"row"} justify={"space-between"} align={"baseline"} spacing={4} style={{width: 200}}>
                        <Search
                            shape="simple"
                            size="medium"
                            style={{marginBottom: "10px"}}
                            onChange={handleSearchTreeNode}
                        />
                        <Icon type="refresh" size={"xs"} title="刷新" onClick={async () => {
                            await refreshResourceTree()
                        }}/>
                    </Box>
                    <Tree
                        checkable={true}
                        editable={false}
                        //selectedKeys={selectedKeys}
                        checkedKeys={checkedKeys}
                        expandedKeys={treeExpandState.expandedKeys}
                        autoExpandParent={treeExpandState.autoExpandParent}
                        // defaultExpandedKeys={["2"]}
                        // defaultCheckedKeys={["2", "4", "5"]}
                        filterTreeNode={filterTreeNode}
                        onExpand={handleExpandTree}
                        // onSelect={handleSelectTreeNode}
                        onCheck={handleCheckTreeNode}
                        dataSource={resourceTreeDataSource}
                    />
                </div>
                :
                <div>无数据</div>
        }

    </div>
};

export default AssignPermissionTree