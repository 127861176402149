import React, {useState} from "react";
import {Navigate, NavLink, Route, Routes, useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import {useAuthz} from "@/contexts/UAAContext";
import Overview from "./pages/Overview";
import {Avatar, Dropdown, Menu, Nav, Shell} from "@alifd/next";
import NotFound from "@/pages/NotFound";
import {useSelectedNavPaths} from "./hooks/useSelectedNavPaths";
import SystemManagement from "@/pages/admin";
import UserManagement from "./pages/admin/UserManagement";
import Welcome from "./pages/admin/Welcome";
import UserDetail from "./pages/admin/UserManagement/UserDetail";
import GroupManagement from "./pages/admin/GroupManagement";
import ClientManagement from "./pages/admin/ClientManagement";
import ClientDetail from "./pages/admin/ClientManagement/ClientDetail";
import RoleManagement from "./pages/admin/RoleManagement";
import RoleDetail from "./pages/admin/RoleManagement/RoleDetail";

const AuthenticatedApp = () => {
    // 路由
    // useRoutes([]);

    const navigate = useNavigate();
    // nav
    const selectedNavPaths = useSelectedNavPaths();
    //
    const {user, logout} = useAuthz();
    // const {hasRole} = useAuthorize();

    const [navcollapse, setNavcollapse] = useState(false);
    const onNavCollapseChange = (collapse?: boolean) => {
        setNavcollapse(collapse || false);
        return {}
    };

    return (
        <Shell
            className={"iframe-hack"}
            device={"desktop"}
            style={{border: "1px solid #eee"}}
        >
            <Shell.Branding>
                <div className="rectangular"></div>
                <span style={{marginLeft: 10}}>统一身份认证系统</span>
            </Shell.Branding>

            <Shell.Navigation direction="hoz" align={"right"}>
                <Nav
                    mode="popup"
                    direction="hoz"
                    type="line"
                    defaultSelectedKeys={selectedNavPaths}
                    triggerType="hover"
                    style={{border: 0}}
                >
                    <Nav.Item icon="list" key="/overview">
                        <NavLink to={"/overview"}>我的应用</NavLink>
                    </Nav.Item>

                    {
                        user?.superAdmin
                            ?
                            <Nav.Item icon="set" key="/admin">
                                <NavLink to={"/admin"}>系统管理</NavLink>
                            </Nav.Item>
                            : <></>
                    }


                </Nav>
            </Shell.Navigation>

            <Shell.Action>
                <Dropdown
                    trigger={<div>
                        <Avatar icon={'account'} size={"small"}/>
                        <span style={{marginLeft: 10}}>{user?.nickname}</span>
                    </div>}
                    triggerType={["click", "hover"]}
                    afterOpen={() => console.log("after open")}
                >
                    <Menu>
                        {/*<Menu.Item>修改密码</Menu.Item>*/}
                        <Menu.Item onClick={() => {logout(() => {
                            window.location.href = window.location.origin;
                        })}}>退出系统</Menu.Item>
                    </Menu>
                </Dropdown>
            </Shell.Action>

            <Shell.Content style={{padding: 0, width: "100%", backgroundColor: '#fff'}}>
                <ContentContainer>
                    <Routes>
                        <Route path={'/overview'} element={<Overview/>}></Route>
                        <Route path={'/admin'} element={<SystemManagement/>}>
                            <Route path={"/"} element={<Welcome/>}/>
                            <Route path={"/user-management"} element={<UserManagement/>}></Route>
                            <Route path={"/user-management/:userId"} element={<UserDetail/>}></Route>
                            <Route path={"/group-management"} element={<GroupManagement/>}></Route>
                            <Route path={"/role-management"} element={<RoleManagement/>}></Route>
                            <Route path={"/role-management/:roleId"} element={<RoleDetail/>}></Route>
                            <Route path={"/client-management"} element={<ClientManagement/>}></Route>
                            <Route path={"/client-management/:groupId"} element={<ClientDetail/>}></Route>
                        </Route>
                        <Route path={"*"} element={<NotFound/>}/>
                        <Navigate to={`${window.location.pathname}/overview`} replace={true}/>
                    </Routes>
                </ContentContainer>
            </Shell.Content>

        </Shell>

    )
};

const NavToggle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
`

const ContentContainer = styled.div`
    min-height: calc(100vh - 54px);
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #fff;
`

export default AuthenticatedApp