import {PagedList} from "@/types/core/PagedList";
import httpClient from "@/utils/httpClient";
import {Client} from "@/types/admin/Client";
import {ClientQueryRequest} from "@/types/admin/QueryClientRequest";
import {ClientSecretUpdateRequest} from "@/types/admin/ClientSecretUpdateRequest";

export function findAllClientsList() {
    return httpClient.get<Client[]>("/api/v1/admin/clients", {
    })
}

export function findClientById(clientId: string | undefined) {
    return httpClient.get<Client>(`/api/v1/admin/clients/${clientId}`)
}

export async function findClientPageList(query: ClientQueryRequest) {
    return httpClient.get<PagedList<Client>>("/api/v1/admin/clients/page", {
        params: query
    })
}

export async function saveClient(client: Partial<Client>) {
    return httpClient.post('/api/v1/admin/clients', {
        data: client
    });
}

export async function updateClient(client: Partial<Client>) {
    return httpClient.put(`/api/v1/admin/clients/${client.clientId}`, {
        data: client
    });
}

export async function updateClientSecret(request: ClientSecretUpdateRequest) {
    return httpClient.put(`/api/v1/admin/clients/${request.clientId}/updateClientSecret`, {
        data: request
    });
}

export async function toggleClientStatus(params: {clientId: String, enabled: boolean}) {
    return httpClient.patch(`/api/v1/admin/clients/${params.clientId}/toggle/status`, {
        params: params
    });
}

export async function deleteClient(id: String) {
    return httpClient.delete(`/api/v1/admin/clients/${id}`);
}