import {useMutation, useQuery, useQueryClient} from "react-query";
import * as userService from "@/services/admin/user.service";
import {User} from "@/types/admin/User";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";
import {PagedList} from "@/types/core/PagedList";
import {useCallback} from "react";
import {TransferResponse} from "@/types/admin/TransferResponse";
import {Client} from "@/types/admin/Client";
import {UserAttribute} from "@/types/admin/UserAttribute";
import {UpdateUserPasswordRequest} from "@/types/admin/UpdateUserPasswordRequest";

export const PAGE_LIST_CACHE_KEY = "UserPageList";

export function useGetUserById(userId: string | undefined) {
    return useQuery<User, HttpError>(['GetUserById', userId], () => {
        return userService.getUserById(userId);
    }, {
        enabled: !!userId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}


export function useFindUserPageList(current: number, pageSize: number) {
    return useQuery<PagedList<User>, HttpError>([PAGE_LIST_CACHE_KEY, current, pageSize], function () {
        return userService.findUserPageList(current, pageSize);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useSaveUser() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, Partial<User>>(async function(user: Partial<User>) {
        return userService.saveUser(user);
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

// 更新用户信息
export function useUpdateUser() {
    const queryClient = useQueryClient();
    return useMutation(async function(user: Partial<User>) {
        return userService.updateUser(user)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

// 更新密码
export function useUpdatePassword() {
    const queryClient = useQueryClient();
    return useMutation(async function(request: UpdateUserPasswordRequest) {
        return userService.updatePassword(request)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

// 修改状态
export function useToggleStatus() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, {id: String, enabled: boolean}>(async function(params: {id: String, enabled: boolean}) {
        return userService.toggleStatus(params)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteUser() {
    const queryClient = useQueryClient();
    return useMutation(async function(userId: string) {
        return userService.deleteUser(userId)
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useRefresh() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
    }, [])
}

export function useFindRoleTransferByUserId(userId: string | undefined) {
    return useQuery<TransferResponse, HttpError>(['RoleTransferByUserId', userId], () => {
        return userService.findRoleTransferByUserId(userId);
    }, {
        enabled: !!userId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useFindClientsByUserId(userId: string | undefined) {
    return useQuery<Client[], HttpError>(['AppTransferByUserId', userId], () => {
        return userService.findClientsByUserId(userId);
    }, {
        enabled: !!userId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useFindMyClientsList() {
    return useQuery<Client[], HttpError>(['MyClientsList'], function () {
        return userService.findMyClientsList();
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

// 查询用户属性列表
export function useFindUserAttributeListByUserId(userId: string | undefined) {
    return useQuery<UserAttribute[], HttpError>(['UserAttributeListByUserId', userId], () => {
        return userService.findUserAttributeListByUserId(userId);
    }, {
        enabled: !!userId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useSaveUserAttribute() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, Partial<User>>(async function(userAttribute: Partial<UserAttribute>) {
        return userService.saveUserAttribute(userAttribute);
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries(["UserAttributeListByUserId"])
        }
    })
}

export function useDeleteUserAttribute() {
    const queryClient = useQueryClient();
    return useMutation(async function(param: {userId: string, attributeId: string}) {
        return userService.deleteUserAttribute(param)
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries(["UserAttributeListByUserId"])
        }
    })
}


// 查找已分配给用户的组
export function useFindAssignedGroupsByUserId(userId: string | undefined) {
    return useQuery<TransferResponse, HttpError>(['GroupTransferByUserId', userId], () => {
        return userService.findAssignedGroupsByUserId(userId);
    }, {
        enabled: !!userId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

// 保存分配组给用户
export function useAssignGroupsToUser(userId: string) {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string[]>(async function(groupIds: string[]) {
        return userService.assignGroupsToUser(userId, groupIds);
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries(["GroupTransferByUserId"])
        }
    })
}