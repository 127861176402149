import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message, Radio, Switch} from "@alifd/next";
import {openErrorNotification} from "@/utils/notificationUtil";
import {useSaveClient} from "@/models/admin/client.model";
import {Client} from "@/types/admin/Client";

export interface AddClientDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddClientDialog: React.FC<AddClientDialogProps> = (props) => {
    const {visible, toggleVisible, onSuccess} = props;
    const field = Field.useField();
    const {mutateAsync} = useSaveClient();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const client = field.getValues<Partial<Client>>();
            mutateAsync(client).then(() => {
                Message.success("新建应用成功！");
                onSuccess();
                handleClose()
            }).catch(reason => {
                openErrorNotification("新建应用失败", reason.message);
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加应用'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="显示名称：" required={true}>
                    <Input name={'displayName'} />
                </Form.Item>
                <Form.Item label="客户端类型：" required={true}>
                    <Radio.Group shape="button" name={"clientType"} defaultValue={'frontend'}>
                        <Radio value="web">web应用</Radio>
                        <Radio value="app">app</Radio>
                        <Radio value="frontend">前端</Radio>
                    </Radio.Group>
                    {/*<Select name="clientType" style={{width: "15rem"}}>
                        <Select.Option value="web">web应用</Select.Option>
                        <Select.Option value="app">app</Select.Option>
                        <Select.Option value="frontend">前端</Select.Option>
                    </Select>*/}
                </Form.Item>
                <Form.Item label="Client KEY：" required={true}>
                    <Input name={'clientKey'} />
                </Form.Item>
                <Form.Item label="客户端描述：" required={true}>
                    <Input.TextArea name="description" />
                </Form.Item>
                <Form.Item label="应用主页：">
                    <Input name="homepageUrl" />
                </Form.Item>
                <Form.Item label="是否在控制台显示：" required={true}>
                    <Switch name="alwaysDisplayInConsole" />
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddClientDialog