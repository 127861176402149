import {useMutation, useQuery, useQueryClient} from "react-query";
import * as clientService from "@/services/admin/client.service";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";
import {PagedList} from "@/types/core/PagedList";
import {Client} from "@/types/admin/Client";
import {ClientQueryRequest} from "@/types/admin/QueryClientRequest";
import {ClientSecretUpdateRequest} from "@/types/admin/ClientSecretUpdateRequest";

export const PAGE_LIST_CACHE_KEY = "ClientPageList";

export function useFindClientPageList(query: ClientQueryRequest) {
    return useQuery<PagedList<Client>, HttpError>([PAGE_LIST_CACHE_KEY, query], function () {
        return clientService.findClientPageList(query);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useGetClientById(clientId: string | undefined) {
    return useQuery<Client, HttpError>(["GetClientById", clientId], () => {
        return clientService.findClientById(clientId);
    }, {
        enabled: !!clientId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useSaveClient() {
    const queryClient = useQueryClient();
    return useMutation(async function(client: Partial<Client>) {
        return clientService.saveClient(client)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateClient() {
    const queryClient = useQueryClient();
    return useMutation(async function(client: Partial<Client>) {
        return clientService.updateClient(client)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateClientSecret(clientId: string | undefined) {
    const queryClient = useQueryClient();
    return useMutation(async function(request: ClientSecretUpdateRequest) {
        return clientService.updateClientSecret(request)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries(["GetClientById", clientId])
        }
    })
}

// 修改状态
export function useToggleClientStatus() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, {clientId: String, enabled: boolean}>(async function(params: {clientId: String, enabled: boolean}) {
        return clientService.toggleClientStatus(params)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteClient() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string>(async function(id: String) {
        return clientService.deleteClient(id)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useFindAllClientsList() {
    return useQuery<Client[], HttpError>(['ALLClientList'], function () {
        return clientService.findAllClientsList();
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}