import {PagedList} from "@/types/core/PagedList";
import httpClient from "@/utils/httpClient";
import {User} from "@/types/admin/User";
import {TransferResponse} from "@/types/admin/TransferResponse";
import {Client} from "@/types/admin/Client";
import {UserAttribute} from "@/types/admin/UserAttribute";
import {UpdateUserPasswordRequest} from "@/types/admin/UpdateUserPasswordRequest";

export async function findUserPageList(current: number, pageSize: number) {
    return httpClient.get<PagedList<User>>("/api/v1/admin/users/page", {
        params: {
            current,
            pageSize
        }
    })
}

// 通过ID获取用户信息
export function getUserById(userId: string | undefined) {
    return httpClient.get<User>(`/api/v1/admin/users/${userId}`)
}

// 保存用户
export async function saveUser(user: Partial<User>) {
    return httpClient.post('/api/v1/admin/users', {
        data: user
    });
}

// 更新用户信息
export async function updateUser(user: Partial<User>) {
    return httpClient.put(`/api/v1/admin/users/${user.userId}`, {
        data: user
    });
}

// 修改密码
export async function updatePassword(request: UpdateUserPasswordRequest) {
    return httpClient.patch(`/api/v1/admin/users/updatePassword`, {
        data: request
    });
}

// 修改用户状态
export async function toggleStatus(params: {id: String, enabled: boolean}) {
    return httpClient.patch(`/api/v1/admin/users/${params.id}/toggle/status`, {
        params: params
    });
}

export function deleteUser(userId: string) {
    return httpClient.delete(`/api/v1/admin/users/${userId}`);
}

export function findRoleTransferByUserId(userId: string | undefined) {
    return httpClient.get<TransferResponse>(`/api/v1/admin/users/${userId}/roles/transfer`)
}

export function findClientsByUserId(userId: string | undefined) {
    return httpClient.get<Client[]>(`/api/v1/admin/users/${userId}/apps`)
}

export function findMyClientsList() {
    return httpClient.get<Client[]>("/api/v1/user/console/applications", {
    })
}

export function findUserAttributeListByUserId(userId: string | undefined) {
    return httpClient.get<UserAttribute[]>(`/api/v1/admin/users/${userId}/attributes`)
}

export async function saveUserAttribute(userAttribute: Partial<UserAttribute>) {
    return httpClient.post(`/api/v1/admin/users/${userAttribute.userId}/attributes`, {
        data: userAttribute
    });
}

export function deleteUserAttribute(param: { userId: string; attributeId: string }) {
    return httpClient.delete(`/api/v1/admin/users/${param.userId}/attributes/${param.attributeId}`);
}

// 查询已分配用户组
export function findAssignedGroupsByUserId(userId: string | undefined) {
    return httpClient.get<TransferResponse>(`/api/v1/admin/permissions/users/${userId}/groups/transfer`)
}

// 分配组给用户
export async function assignGroupsToUser(userId: string, groupIds: string[]) {
    return httpClient.post(`/api/v1/admin/permissions/users/${userId}/groups`, {
        data: {
            userId: userId,
            groupIds: groupIds
        }
    });
}