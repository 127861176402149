import React, {useEffect} from "react";
import {Box, Breadcrumb, Button, Field, Form, Grid, Icon, Input, Loading, Message, Switch, Tab} from "@alifd/next";
import {PageContainer} from "@/components/lib";
import {defaultFormItemLayout} from "@/contants/Constans";
import {openErrorNotification} from "@/utils/notificationUtil";
import {useURLPathParams} from "@/hooks/useURLPathParams";
import * as roleModel from "@/models/admin/role.model";
import * as clientModel from "@/models/admin/client.model";
import {Role} from "@/types/admin/Role";
import TabContentContainer from "@/components/TabContentContainer";
import _ from "lodash";
import AssignPermissionTree from "./components/AssignPermissionTree";
import {HttpError} from "@/httpclient";
import {useNavigate} from "react-router-dom";
import {useURLSearchParams} from "@/hooks/useURLSearchParams";

const RoleDetail = () => {
    const navigate = useNavigate();
    const pathParams = useURLPathParams("/admin/role-management/:roleId", ["roleId"]);
    const roleId = pathParams?.roleId;
    const [{roleName}] = useURLSearchParams(['roleName'])
    // 查询角色
    const {data: roleInfo, isLoading: isLoadingRoleInfo} = roleModel.useGetRoleById(roleId);
    const {mutateAsync: updateRoleMutateAsync} = roleModel.useUpdateRole();
    // 查询所有应用
    const {data: allClients, isLoading: isLoadingAllClient} = clientModel.useFindAllClientsList();
    const {mutateAsync: deleteRoleFn} = roleModel.useDeleteRole();

    const roleInfoField = Field.useField({forceUpdate: false});

    useEffect(() => {
        roleInfoField.setValues(roleInfo)
    }, [roleInfo]);

    function handleSubmitClientInfo() {
        roleInfoField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const role = roleInfoField.getValues<Partial<Role>>();
            updateRoleMutateAsync(role).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    function handleClickTabItem(key: string) {
        console.log(key);
    }

    // 删除
    function handleDelete() {
        if (roleId) {
            deleteRoleFn(roleId).then(() => {
                Message.success("删除成功！");
                navigate(-1);
            }).catch((reason: HttpError) => {
                openErrorNotification("删除失败", reason.message)
            })
        }
    }

    return isLoadingRoleInfo ? (<Loading fullScreen={true}/>) : (
        <PageContainer>
            <Breadcrumb>
                <Breadcrumb.Item link="/admin/role-management">角色管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <b>{roleName}</b>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Box direction={"row"} justify={"flex-start"} align={"center"} spacing={10} style={{marginTop: '1rem'}}>
                {/*<Icon size={"xs"} type="arrow-left" />*/}
                <label>{roleName}</label>
                <Icon size={"small"} type="ashbin" onClick={handleDelete} role={"button"} aria-label="icon ashbin"
                      style={{cursor: "pointer"}}/>
            </Box>

            <Tab>
                <Tab.Item title="角色信息" key="1">
                    <TabContentContainer>
                        <div style={{minWidth: '60rem'}}>
                            <Form {...defaultFormItemLayout} colon={true} field={roleInfoField}>
                                <Form.Item label="Role ID：" required={true} isPreview={true}>
                                    <Input name={'roleId'}/>
                                </Form.Item>
                                <Form.Item label="角色名称：" required={true} isPreview={true}>
                                    <Input name="roleName"/>
                                </Form.Item>
                                <Form.Item label="显示名称：">
                                    <Input name="displayName"/>
                                </Form.Item>
                                <Form.Item label="角色描述：">
                                    <Input.TextArea name="description"/>
                                </Form.Item>
                                {/*<Form.Item label="是否内置：">
                                <Switch name="buildIn"/>
                            </Form.Item>*/}
                                <Form.Item label="是否启用：">
                                    <Switch name="enabled"/>
                                </Form.Item>
                            </Form>

                            <Grid.Row>
                                <Grid.Col span="6"></Grid.Col>
                                <Grid.Col span="18">
                                    <Button type={"primary"} onClick={handleSubmitClientInfo}>提交</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    </TabContentContainer>

                </Tab.Item>
                {/*<Tab.Item title="关联组" key="2">
                    关联组
                </Tab.Item>
                <Tab.Item title="关联用户" key="3">
                    关联用户
                </Tab.Item>*/}
                {/*<Tab.Item title="分配应用" key="4">
                    分配应用
                </Tab.Item>*/}
                <Tab.Item title="分配权限" key="5">
                    <Tab shape={"text"} tabPosition={"top"} excessMode={"slide"}
                         tabRender={(key: string, props: {}) => {
                             const client = _.find(allClients, (x) => {
                                 return x.clientId === key;
                             });
                             return <div style={{margin: '1rem'}}>
                                 <Icon type="account" size={"xs"}/> {client?.displayName}
                             </div>
                         }}>
                        {allClients?.map(item => (
                            <Tab.Item key={item.clientId} title={item.displayName} onClick={handleClickTabItem}>
                                <div style={{marginLeft: '1rem'}}>
                                    <AssignPermissionTree roleId={String(roleId)} clientId={item.clientId}/>
                                </div>
                            </Tab.Item>
                        ))}
                    </Tab>
                </Tab.Item>
            </Tab>
        </PageContainer>
    )
}

export default RoleDetail