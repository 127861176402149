import React from 'react';
/*import 'antd/dist/antd.less'*/
import '@alifd/theme-blue-1/dist/next.css';
import './App.css';
import {useAuthz} from "@/contexts/UAAContext";
import UnauthenticatedApp from "./UnauthenticatedApp";
import AuthenticatedApp from "./AuthenticatedApp";

import { library } from '@fortawesome/fontawesome-svg-core'; // 导入图标仓库
import { fas } from '@fortawesome/free-solid-svg-icons'; // 全部图标
import { fab } from '@fortawesome/free-brands-svg-icons'; // 品牌图标

import '@/components/FaIcon/index.less'

//把图标添加进仓库
library.add(fas, fab);

function App() {
    const {user} = useAuthz();

    return (
        <div className="App">
            {user ? <AuthenticatedApp/> : <UnauthenticatedApp/>}
        </div>
    );
}

export default App;