import React from "react";
import {PageContainer} from "@/components/lib";
import {Box} from "@alifd/next";
import {Client} from "@/types/admin/Client";
import * as userModel from "@/models/admin/user.model";
import styled from "@emotion/styled";
import AppIcon from "@/assets/APP-322-216.png"
import "./index.css"
// import AppIcon from "@/assets/running.svg"

const handleClick = (item: Client) => {
    if (item.homepageUrl) {
        window.location.href = item.homepageUrl;
    }
};

const Overview: React.FC = () => {

    const {data, isLoading} = userModel.useFindMyClientsList();

    return (
        <PageContainer direction={"row"} align={"flex-start"}>
            <div style={{padding: '10px'}}>
                <Box device="desktop" direction="row"
                     wrap={true} /*spacing={20}*/
                     justify="space-between" align="center"
                     className={"myapp-container"}
                >

                    {
                        data?.map(x => {
                            return <Box justify="center" align="center" className={"app-item"} key={x.clientId}>
                                <img
                                    src={AppIcon}
                                    className={"app-img"}
                                    alt={x.displayName}
                                />
                                <AppKey>{x.clientKey}</AppKey>
                                <a className={"app-name"} href={x.homepageUrl}>{x.displayName}</a>
                            </Box>

                        })
                    }

                    {/*<Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>
                    <Box justify="center" align="center" className="empty-appitem"></Box>*/}

                </Box>
            </div>
        </PageContainer>
    )
}

const AppKey = styled.div`
    padding: 8px 8px;
`

export default Overview